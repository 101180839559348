@font-face {
  font-family: 'bm-menu';
  src:
    url('fonts/bm-menu.ttf?tqodzt') format('truetype'),
    url('fonts/bm-menu.woff?tqodzt') format('woff'),
    url('fonts/bm-menu.svg?tqodzt#bm-menu') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="bm-menu-"], [class*=" bm-menu-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bm-menu' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bm-menu-marketplace:before {
  content: "\70";
}
.bm-menu-availability:before {
  content: "\61";
}
.bm-menu-booking:before {
  content: "\62";
}
.bm-menu-channel:before {
  content: "\63";
}
.bm-menu-dashboard:before {
  content: "\64";
}
.bm-menu-help:before {
  content: "\65";
}
.bm-menu-home:before {
  content: "\66";
}
.bm-menu-ibemanager:before {
  content: "\67";
}
.bm-menu-metrics:before {
  content: "\68";
}
.bm-menu-pages:before {
  content: "\69";
}
.bm-menu-rates:before {
  content: "\6a";
}
.bm-menu-usermanager:before {
  content: "\6b";
}
.bm-menu-usersettings:before {
  content: "\6c";
}
.bm-menu-egg:before {
  content: "\6d";
}
.bm-menu-credit-card:before {
  content: "\6e";
}
.bm-menu-seekda-pay:before {
  content: "\71";
}
.bm-menu-marketing:before {
  content: "\6f";
}
