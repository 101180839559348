/* You can add global styles to this file, and also import other style files */

* {
    box-sizing: border-box;
}
html, body {
    height: 100%;
}
body {
    margin: 0;
    background-color: #fff;
    /*
    overflow-y: hidden;
    overflow-x: hidden;
    */
}

a {
  cursor: pointer;
}

button:active,
button:focus {
  outline: none !important;
}

.middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.embedded-body bm-modal[fixedHeight] .bm-modal {
    height: 600px;
}

.embedded-container-max-width {
    max-width: 1360px;
    margin: 0 auto;
}

.bm-table-toolbar bm-search-bar .bm-icon-times { margin-top: 1px !important; }

/* Clearfix */
.cf:before,
.cf:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cf:after {
    clear: both;
}

.bm-upload-area {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
}

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/media";
@import "bootstrap/scss/print";
